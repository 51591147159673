import React, { useEffect } from "react";
import { Container } from '@material-ui/core';
import PageHead from 'components/global/PageHead/PageHead';
import MultiTopic from "components/MultiTopic/MultiTopic";
import ifImage from "../../../src/assets/png/if2020.png"

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <PageHead pageName="Kontakt"/>
      <MultiTopic
        topics = {[
          
              {
                heading: "Interactive Future - Team",
                paragraph: <a className="text-yellow" href="mailto:management@if-exhibition.de">management@if-exhibition.de</a>,
                image: ifImage
              },
              {
                heading: "Studiengangsleitung",
                paragraph: <a className="text-yellow" href="mailto:andrea.krajewski@h-da.de">andrea.krajewski@h-da.de</a>,
          
              },
              {
                heading: "Studentische Studienberatung",
                paragraph: <a className="text-yellow" href="mailto:imd-studieren@h-da.de">imd-studieren@h-da.de</a>,
          
              },
        ]}
       
      /> 
    </Container>
  );
};

export default Contact;
