import axios from "axios";

export const findLiveStreamInfos: any = async () => {
  const res: any = await axios
    .get(`/livestream-infos.json`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        throw error;
      }
    );
  return res;
};
