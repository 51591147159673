import React, { useEffect } from "react";
import "./Footer.scss";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import iLoveIMD from "assets/svg/iloveimd.svg";
import instagramLogo from "assets/svg/instagram.svg";
import mastodonLogo from "assets/svg/mastodon.svg";
import heart from "assets/svg/heart.svg";
import * as LogoService from "services/LogoService.tsx";

const Footer = () => {
	const [fetchData, setFetchData] = React.useState([]);

	const getData = async () => {
		const res = await LogoService.findAllLogos();
		setFetchData(res);
	};

	useEffect(() => {
		getData();
	}, []);

	const fetchedLogos = fetchData?.map(logo => {
		let sizeDiscriminator = "";
		logo.isBig === true ? sizeDiscriminator = "bigLogo" : sizeDiscriminator = "";
		const logoUrl = (logo.Logos[0] || {}).url;
		return (
			<Grid
				className={"footer_sponsors-content_sponsor big"} 
				item
				xs={12}
				sm={6}
				md={4}
			>
				<img
					src={logoUrl}
					className={sizeDiscriminator}
					alt={logo.altText}
					title={logo.title}
				/>
			</Grid>
		);
	});

	return (
		<footer className="footer">
			<Grid container spacing={0}>
				<Grid item xs={1}></Grid>
				<Grid item xs={10}>
					<div className="footer_sponsors-heading">
						<h2>
							Danke
							<img
								src={heart}
								alt="heart"
							></img>
						</h2>
						<h2>an unsere Supporter</h2>
					</div>
					<Grid
						className="footer_sponsors-content"
						container
					>
						{fetchedLogos}
					</Grid>
					<Grid
						className="footer_sponsors-content"
						container
					>
						{/* {sponsors} */}
					</Grid>
				</Grid>
				<Grid
					className="footer_real"
					container
					spacing={1}
				>
					<Grid item xs={1}></Grid>
					<Grid
						className="footer_real_information"
						item
						xs={10}
						md={5}
					>
						<Link
							to="/legal-notice"
							className="footer_real_information_site"
						>
							Impressum
						</Link>
						<Link
							to="/contact"
							className="footer_real_information_site"
						>
							Kontakt
						</Link>
						<Link
							to="/privacy"
							className="footer_real_information_site"
						>
							Datenschutz
						</Link>
					</Grid>
					<Grid
						className="footer_remove"
						item
						xs={1}
						md={0}
					></Grid>
					<Grid
						className="footer_remove"
						item
						xs={1}
						md={0}
					></Grid>
					<Grid
						className="footer_real_social"
						item
						xs={10}
						md={5}
						spacing={0}
					>
						<a
							href="https://imd.mediencampus.h-da.de/" target="_blank" rel="noopener noreferrer"
							className="footer_real_social_link"
						>
							<img
								src={iLoveIMD}
								alt="IMD Logo: I love IMD"
							></img>
						</a>
						<a
							href="https://www.instagram.com/interactivemediadesign.hda" target="_blank" rel="noopener noreferrer"
							className="footer_real_social_link"
						>
							<img
								src={
									instagramLogo
								}
								alt="Instagram Logo"
							></img>
							<span>Instagram</span>
						</a>
						<a
							href="https://imd.social/@imd" target="_blank" rel="noopener noreferrer"
							className="footer_real_social_link"
						>
							<img
								src={
									mastodonLogo
								}
								alt="Mastodon Logo"
							></img>
							<span>Mastodon</span>
						</a>
					</Grid>
				</Grid>
			</Grid>
		</footer>
	);
};

export default Footer;
