import React from "react";
//Bilder
import ifExhibition from "assets/jpg/bg-what-the-if.jpg";
import ifExhibitionXs from "assets/jpg/bg-what-the-if-xs.jpg";

import heart from "assets/png/herz.png";
import smartphone from "assets/png/smartphone.png";
import desktop from "assets/png/desktop.png";

import HeroSpace from "components/global/HeroSpace/HeroSpace";

import SemesterTopicInfoCards from "components/SemesterTopicInfoCard/SemesterTopicInfoCards";
import { Container } from "@material-ui/core";
import MultiTopic from "components/MultiTopic/MultiTopic";

const IfExhibition = () => {
  return (
    <div>
      <HeroSpace
        image={ifExhibition}
        xsImage={ifExhibitionXs}
        h2="noText"
        h1="What the if?"
        subtitle="Die sechste Werkschau des Studiengangs Interactive Media Design und zum ersten Mal digital."
        alt="React App"
        textRight={true}
      />
      <Container fixed>
        <MultiTopic
          topics={[
            {
              heading: "Die Interactive Future Exhibition",
              paragraph:
                "Auf der jährlichen Werkschau des Studiengangs Interacitve Media Design zeigen die Studierenden ihre Projekte des vergangenen Sommersemesters. In der dreitägigen Ausstellung kannst Du alle Projekte aus den drei Semstern in aller Ruhe ansehen und die Macher treffen. Darüber hinaus erwarten dich Präsentationen und ein Date mit der Medieninustrie.",
              image: smartphone,
            },
            {
              heading: "Interactive Media Design",
              paragraph:
                "Das Studium ist praxisorientiert. In jedem Semester werden in kleinen Teams Projekte durchgeführt. Die Studieninhalte werden von einem interdisziplinären Betreuerteam direkt in den Projektteams vermittelt. Die Projekte haben häufig einen direkten Marktbezug oder werden gemeinsam mit einem Unternehmen durchgeführt. Projektpartner sind z.B. Honda, Opel, Städel-Museum, ZDF, Siemens oder Telekom.",
              image: heart,
              button: {
                title: "Zum Studiengang",
                externalUrl: "https://imd.mediencampus.h-da.de",
              },
            },
            {
              heading: "Die Challenges der Semester",
              paragraph:
                "Wir gestalten alle Interaktionen, doch wie sehen diese in den Semestern aus? In jedem Semester gibt es eine bestimmte Disziplin, der sich die Studierenden stellen. All unsere Projekte sind mit Herzblut entstanden und sind bereit, der Welt präsentiert zu werden.",
              image: desktop,
            },
          ]}
        ></MultiTopic>
        <SemesterTopicInfoCards
          simulation={{
            headline: "Simulation",
            paragraph:
              "Wozu braucht man Simulationen? In einer interaktiven Simulation kannst Du auch solche Prozesse aktiv und Schritt für Schritt nachvollziehen, die im echten Leben z.B. unsichtbar oder zu komplex für ein schnelles Durchdringen sind.",
          }}
          iot={{
            headline: "Internet of Things",
            paragraph:
              "Wie leben wir im Internet der Dinge? Dass sich die Alltagsgegenstände unserer Umgebung untereinander vernetzen, um Informationen auszutauschen und uns ungefragt Dienstleistungen anzubieten, kann man sich heute noch kaum vorstellen. Wir haben es im vierten Semester schon einmal getan.",
          }}
          ambient={{
            headline: "Ambient Intelligent Spaces",
            paragraph:
              "Was, wenn die Umgebung schlau wird? Dass greifbare Alltagsobjekte smart werden, wird unter dem Thema IoT behandelt. Was aber, wenn der Computer ganz verschwindet und einfach nur unsere Lebensumgebung auf uns reagiert? Wir nennen das „Ambient Intelligent Spaces“, also smarte Lebensumgebungen.",
          }}
        />
      </Container>
    </div>
  );
};

export default IfExhibition;
