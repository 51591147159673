import React from "react";
import "./PeopleCards.scss";
import PeopleCard from "./PeopleCard/PeopleCard";
import Grid from "@material-ui/core/Grid";
import arrows from "../../assets/svg/arrows.svg";
import { NavLink } from "react-router-dom";

const PeopleCards = (props) => {
  return (
    <div>
      <div className="peopleCards_container">
        {props.people.map((person) => (
          <PeopleCard
            name={person.name}
            subline={person.about}
            mail={person.email}
            img={person.img}
            link={person.link}
          ></PeopleCard>
        ))}
      </div>
      <Grid container>
        <Grid item xs={6}></Grid>
        <Grid item xs={5}>
          <img
            className="peopleCards_arrows"
            src={arrows}
            alt="scroll horicontal"
          ></img>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className="home-centered-container">
          {props.button ? (
            <NavLink
              to={props.button.link}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              style={{ textDecoration: "none" }}
            >
              <button className="primary home-centered-button">
                {props.button.text}
              </button>
            </NavLink>
          ) : null}
        </div>
      </Grid>
    </div>
  );
};

export default PeopleCards;
