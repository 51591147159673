import React, { useEffect } from "react";
import { Container } from '@material-ui/core';
import alexandraDeschampsSonsino from "assets/jpg/speaker_img_alexandraDeschampsSonsino.jpg";
import aralBalkan from "assets/jpg/speaker_img_aralBalkan.jpg";
import eliseMarcus from "assets/jpg/speaker_img_eliseMarcus.jpg";
import lilyHiggins from "assets/jpg/speaker_img_lilyHiggins.jpg";
import MultiTopic from "components/MultiTopic/MultiTopic";

const Speakers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <h1>Speaker</h1>
      <MultiTopic
        topics = {[
        {
          heading: "Alexandra Deschamps Sonsino",
          paragraph: "IoT-Expertin, Autorin des Buches „Smarter Homes: how technology will change your home life”, Gründerin des IoT Meetup London. Sie ist die Designerin der Good Night Lamp, die sich in der ständigen Sammlung des Londoner Design Museums befindet. Ihre Projekte sind auch in der ständigen Sammlung des Museum of Modern Art in New York zu sehen. Neben anderen Würdigungen wurde sie 2017 und 2018 auf die Longlist der „Computer Weekly’s Most Influential Women in UK Technology“ aufgenommen.",
          image: alexandraDeschampsSonsino
        },
        {
          heading: "Aral Balkan",
          paragraph: "Digital Rights-Aktivist, Datenschutz-Evangelist. Redner, Autor und Gründer der Small Technologies Foundation, die sich mit der Entwicklung datenfairer Software beschäftigt. Er hält Vorträge zum Thema Ethical Design und Surveillance Capitalism und beweist mit seiner gemeinnützigen Organisation, dass es Wege zur Selbstbestimmung in einer digital vernetzten Welt gibt.",
          image: aralBalkan,
          imageLeft: "true"
        },
        {
          heading: "Elise Marcus",
          paragraph: "Interdisziplinäre Künstlerin, verändert als „Sustainable Mindset-Designerin“ mit ihren Werken und Workshops die kulturelle Denkweise über soziale und ökologische Nachhaltigkeit. Für ihre oft provokante, futuristische und zum Nachdenken anregende Arbeit wurde sie mehrfach ausgezeichnet.",
          image: eliseMarcus
        },
        {
          heading: "Lily Higgins",
          paragraph: "Zukunftsorientrierte Gestalterin an der Schnittstelle von Strategie und Serious Play. Für ihre Dissertation „Future Flex: A Play-Based Approach to Organizational Change in the Age of Uncertainty“ wurde sie für den Bachelor-Forschungspreis 2017 der Willem de Kooning Academy nominiert. Die Gründerin von Urban Playwalks Rotterdam, arbeitet unter anderem für Changeist und Playspace.",
          image: lilyHiggins,
          imageLeft: "true"
        }
        ]}
        alternateBackgroundColor="true"
      />
    </Container>
  );
};

export default Speakers;
