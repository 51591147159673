import React, { useEffect } from "react";
//import { makeStyles } from "@material-ui/core/styles";
import "assets/styles/variables.scss";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import ProgramData from "assets/program.json";
import { NavLink } from "react-router-dom";

import "./Home.scss";

//Bilder
import alexandraDeschamps from "assets/jpg/speaker_img_alexandraDeschampsSonsino.jpg";
import aralBalkan from "assets/jpg/speaker_img_aralBalkan.jpg";
import eliseMarcus from "assets/jpg/speaker_img_eliseMarcus.jpg";
import lilyHiggins from "assets/jpg/speaker_img_lilyHiggins.jpg";
import projectsImage from "assets/png/project-preview.png";
import headerImg from "assets/jpg/bg-interactive-future.jpg";
import headerImgXs from "assets/jpg/bg-interactive-future-xs.jpg";
import desktop from "assets/png/desktop.png";

//import PresentationDescription from "components/presentation-description/PresentationDescription";

//import "antd/dist/antd.css";
import HeroSpace from "components/global/HeroSpace/HeroSpace.jsx";
import PeopleCards from "components/PeopleCards/PeopleCards.jsx";
import Topic from "components/MultiTopic/Topic/Topic.jsx";

import MultiTopic from "components/MultiTopic/MultiTopic";
//Chat-Komponent
// import Dashboard from "components/chat/Dashboard";
// import Store from "components/chat/Store";

//VideoGallery-Komponent
//import VideoGallery from "components/videoGallery/VideoGallery"

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HeroSpace
        image={headerImg}
        xsImage={headerImgXs}
        h2="2020"
        h1="interactive future"
        subtitle="Online-Werkschau des Studiengangs Interactive Media Design"
        alt="React App"
      />
      <Container fixed>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MultiTopic
              topics={[
                {},
                {
                  heading:
                    "Roboter, Hologramme und Virtual Reality – klingt nach Science-Fiction?",
                  paragraph:
                    "Für Studierende von Interactive Media Design gehören sie zum Arbeitsalltag! Mit der Interactive Future Exhibition (if) öffnet der Studiengang die Türen für die Öffentlichkeit und bietet mit täglichen Projektausstellungen, -präsentationen, Workshops und interessanten Beiträgen von weltweit bekannten Speakern Einblick in ihre Welt!",
                  image: projectsImage
                },
              ]}
            ></MultiTopic>
            {/* <TimeTable tables={ProgramData.dates} /> */}
          </Grid>
          <Grid item xs={12}>
            <video controls width="100%" height="auto">
              <source src="https://2020.if-exhibition.de/video/IMD_IF2020-Trailer.mp4" type="video/mp4"/>
            </video>
          </Grid>
          <Grid item xs={12} sm={7}>
            <p class="h2 text-yellow">Das war die if2020</p>
            <p class="text-white">
              Drei aufregende Tage mit vollem Programm:
              Studienprojekte, Gastvorträge, Workshops und eine Kochshow sind nur die Highlights von diesem Jahr. 
              Wie jedes Mal wurde die if vollständig von den Studierenden auf die Beine gestellt –
              und diesmal ganz spontan komplett online.
            </p>
          </Grid>
          <Grid item xs={12} sm={5}>
            <NavLink
                to={"program"}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                style={{ textDecoration: "none" }}>
              <button className="primary home-centered-button">
                Zur Videoaufzeichnung
              </button>
            </NavLink>
          </Grid>
          <Topic heading="Die Studienprojekte"
            paragraph="Das Semester über haben die Studierenden nicht nur die if vorbereitet, sondern vor allem an ihren Projekten gearbeitet. Entdecken Sie, welche Lösungen sie für die unterschiedlichsten Probleme gefunden haben."
            button={{title:"Zu den Projekten",url:"projects"}}
            image={desktop}
            imageLeft={true}/>
          <Grid item xs={12} sm={7}>
            <div class="home-speaker-textbox">
              <p class="h2 text-yellow">Speaker</p>
              <p class="text-white">
                Vier spannende Gastvorträge zum Thema digitale
                Zukunftsgestaltung könnt ihr von diesen Persönlichkeiten live
                einsehen!
              </p>
            </div>
          </Grid>
          <Grid item xs={0} sm={5}></Grid>
          <Grid item xs={12}>
            <PeopleCards
              button={{
                text: "Erfahre mehr über die Speaker",
                link: "/speakers",
              }}
              people={[
                {
                  name: "Alexandra Deschamps Sonsino",
                  subline: "Product Manager",
                  img: alexandraDeschamps,
                  mail: "dora.campbell@stud.h-da.de",
                  link: "/speakers",
                },
                {
                  name: "Aral Balkan",
                  subline: "Product Manager",
                  img: aralBalkan,
                  link: "/speakers",
                },
                {
                  name: "Elise Marcus",
                  subline: "Product Manager",
                  img: eliseMarcus,
                  link: "/speakers",
                },
                {
                  name: "Lily Higgins",
                  subline: "Product Manager",
                  img: lilyHiggins,
                  mail: "dora.campbell@stud.h-da.de",
                  link: "/speakers",
                },
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Home;
