import axios from "axios";

export const findAllProjects: any = async () => {
  const res: any = await axios
    .get(`/projekts.json`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        throw error;
      }
    );
  return res;
};

export const findProjectById: any = async (id: string) => {
  const res: any = await axios
    .get(`/projekts.json`)
    .then(
      (response) => {
        const itemArray = JSON.parse(response.data).filter((item: any) => {
          return item._id === id;
        });
        return JSON.stringify(itemArray);
      },
      (error) => {
        throw error;
      }
    );
  return res;
};