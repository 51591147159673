import React from "react";
import HeroSpace from "components/global/HeroSpace/HeroSpace";
import TimeTableCard from "components/TimeTable/TimeTableCard/TimeTableCard";
import { Grid, Container } from "@material-ui/core";
import "./Program.scss";
import ProgramData from "assets/program.json";

//Bilder
import startPic from "assets/png/start.png";
import wednesday from "assets/png/mittwoch.png";
import thursday from "assets/png/donnerstag.png";
import friday from "assets/png/freitag.png";

import desktop from "assets/png/desktop.png";
import emoji from "assets/png/emoji.png";
import TimeTable from "components/TimeTable/TimeTable";

class Program extends React.Component {
	state = {
		currentVideo: "IMD_IF2020-Trailer.mp4"
	};
	setVideo(newVideo) {
		console.log(newVideo);
		this.setState({ currentVideo: newVideo });
	}
	render() {
		return (
			<>
				<HeroSpace h2="" h1="Interactive Future 2020" subtitle="" alt="" />

				<div className={"stream-wrapper"}>
					<video
						controls
						title={"videoPlayer"}
						allowfullscreen
						src={
							"https://2020.if-exhibition.de/video/" + this.state.currentVideo
						}
					></video>
				</div>
				<TimeTable
					tables={ProgramData.dates}
					setVideo={newVideo => this.setVideo(newVideo)}
				/>
			</>
		);
	}
}

export default Program;
