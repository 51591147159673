import React, { useEffect } from "react";
import "./Stage.scss";
import RunningProgramInfo from "components/RunningProgramInfo/RunningProgramInfo";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import * as ProjectService from "services/ProjectService.tsx";
import { useHistory, withRouter } from "react-router-dom";
import * as LiveStreamInfoService from "services/LivestreamInfosService";
import * as ElectivesService from "services/ElectivesService";
const Stage = (props) => {
  const history = useHistory();
  const [oneProjectData, setOneProjectData] = React.useState([]);

  const [liveStreamInfo, setLiveStreamInfo] = React.useState([]);

  var res_single;
  const getData = async () => {
    const res_info = await LiveStreamInfoService.findLiveStreamInfos();
    setLiveStreamInfo(res_info);

    if (liveStreamInfo[0]) {
      switch (liveStreamInfo[0].EventType) {
        case "project":
          res_single = await ProjectService.findProjectById(
            liveStreamInfo[0].projectId
          );
          setOneProjectData(res_single);
          break;
        case "elective":
          res_single = await ElectivesService.findElectiveById(
            liveStreamInfo[0].projectId
          );
          setOneProjectData(res_single);
          break;
      }
    }
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    // getData();
    const interval = setInterval(() => {
      getData();
    }, 30000);

    return () => clearInterval(interval);
  }, [getData]);

  let runningProgrammInfo_headline = "if-Ausstellung";
  let runningProgrammInfo_paragraph =
    "Werkschau des Studiengangs Interactive Media Design";
  let runningProgrammInfo_projectId = "/program";
  let runningProgrammInfo_buttonDes = "Zum Programm";

  if (liveStreamInfo && liveStreamInfo[0]) {
    if (liveStreamInfo[0].EventType === "free") {
      runningProgrammInfo_headline = liveStreamInfo[0].Title;
      runningProgrammInfo_paragraph = liveStreamInfo[0].description;
    }
  }
  if (oneProjectData && oneProjectData[0]) {
    switch (liveStreamInfo[0].EventType) {
      case "project":
        runningProgrammInfo_headline = oneProjectData[0].projectTitle;
        runningProgrammInfo_paragraph = oneProjectData[0].description;
        runningProgrammInfo_projectId = "/project/" + oneProjectData[0].id;
        runningProgrammInfo_buttonDes = "Zur Projektseite";
        break;
      case "elective":
        runningProgrammInfo_headline = oneProjectData[0].projectTitle;
        runningProgrammInfo_paragraph = oneProjectData[0].paragraph;
        runningProgrammInfo_projectId = "/elective/" + oneProjectData[0].id;
        runningProgrammInfo_buttonDes = "Zur Electiveseite";
        break;
    }
  }

  return (
    <div className={"page-stage"}>
      <Grid container spacing={1}>
        <Grid xs={12} lg={9}>
          <div className={"stream-wrapper"}>
            <iframe
              title={"stage-stream"}
              id={"stream"}
              src={
                "https://video.if-exhibition.de/view/47f4987a-9d16-4efc-8520-1ac147b4edbc/?embedded=True&countViewers=False"
              }
            ></iframe>
          </div>
        </Grid>
        <Grid xs={12} lg={3}>
          <div className={"chat-wrapper"}>
            <iframe
              title={"stage-chat"}
              id={"chat"}
              src={
                "https://video.if-exhibition.de/view/47f4987a-9d16-4efc-8520-1ac147b4edbc/?chatOnly=True"
              }
            ></iframe>
          </div>
        </Grid>
      </Grid>
      <div
        className={"running-program-info-wrapper"}
        style={{
          backgroundColor: "rgba(106,87,206,0.5)",
        }}
      >
        <Container fixed>
          <RunningProgramInfo
            headline={runningProgrammInfo_headline}
            paragraph={runningProgrammInfo_paragraph}
            projectId={runningProgrammInfo_projectId}
            buttonDes={runningProgrammInfo_buttonDes}
          />
        </Container>
      </div>
      {/* <VideoGallery videos={videoMockData} /> */}
    </div>
  );
};

export default withRouter(Stage);
