import React, { useEffect } from "react";
import * as ElectivesService from "services/ElectivesService.tsx";
import ProjectGrid from "components/ProjectGallery/ProjectGallery";
import PageHead from "components/global/PageHead/PageHead";
import Container from "@material-ui/core/Container";

const Electives = () => {
  const [fetchData, setFetchData] = React.useState([]);

  const getData = async () => {
    const res = await ElectivesService.findAllElectives();
    setFetchData(res);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  return (
    <div>
      <Container>
        <PageHead pageName={"Electives"} />
      </Container>
      <ProjectGrid projects={fetchData} siteName={"elective"}></ProjectGrid>
    </div>
  );
};

export default Electives;
