import React, { useEffect } from "react";
import * as ProjectService from "services/ProjectService.tsx";
import ProjectGrid from "components/ProjectGallery/ProjectGallery";
import PageHead from "components/global/PageHead/PageHead";
import Container from "@material-ui/core/Container";

const Projects = () => {
  const [fetchData, setFetchData] = React.useState([]);

  const getData = async () => {
    const res = await ProjectService.findAllProjects();
    setFetchData(res);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  return (
    <div>
      <Container>
        <PageHead pageName={"Projekte"} />
      </Container>
      <ProjectGrid projects={fetchData} siteName={"project"}></ProjectGrid>
    </div>
  );
};

export default Projects;
