import React from 'react';
import './ImageGallery.scss';

const ImageGallery = props => {
  return (
    <div className="imageGallery_container">
      {Array.isArray(props.images) ? props.images.map((image) => (
        <img src={image.url}
          alt={image.alternativeText}
        />
      )) :
      null}
    </div>
  )
}

export default ImageGallery;
