import React from "react";
import "./InfoCard.scss";

const InfoCard = (props) => (
  <div className={"infoCard_container box-shadow"}>
    <h2 className={"infoCard_headline"}>{props.headline}</h2>
    <p>{props.paragraph}</p>
  </div>
);

export default InfoCard;
