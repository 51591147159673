import React from "react";
import "./PageHead.scss";

const PageHead = (props) => {
  return (
    <div className="pageHead">
        <h1>{props.pageName}&nbsp;──</h1>
    </div>
  );
};

export default PageHead;
