import React from "react";
import "./RunningProgramInfo.scss";
import Grid from "@material-ui/core/Grid";
import { useHistory, withRouter } from "react-router-dom";

const RunningProgramInfo = (props) => {
  const history = useHistory();
  return (
    <div className={"running-program-info"}>
      <Grid item xs={12} sm={6}>
        {/* <div class="programm-text-Button-container"> */}
        <h2 class="text-yellow">{props.headline}</h2>
        <p class="text-white">{props.paragraph}</p>
      </Grid>
      <Grid item xs={0} sm={3}>
        {props.projectId ? (
          <button
            class="button secondary"
            onClick={() => history.push(props.projectId)}
          >
            {props.buttonDes}
          </button>
        ) : null}
      </Grid>
    </div>
  );
};

export default withRouter(RunningProgramInfo);
