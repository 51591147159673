import React, { useEffect } from "react";
import HeroSpace from "components/global/HeroSpace/HeroSpace";
import VideoGallery from "components/VideoGallery/VideoGallery";
import ImageGallery from "components/ImageGallery/ImageGallery";
import * as ElectivesService from "services/ElectivesService.tsx";
import Grid from "@material-ui/core/Grid";

import { useLocation } from "react-router-dom";
import "./Elective.scss";
import "./ElectiveRow.scss";
import { Container } from "@material-ui/core";

const Elective = (props) => {
  const [oneElectiveData, setOneElectiveData] = React.useState([]);

  const { pathname } = useLocation();

  const URLseparated = pathname.split("/");
  const URLelectiveID = URLseparated[URLseparated.length - 1];

  const getData = async () => {
    const res_single = await ElectivesService.findElectiveById(URLelectiveID);
    setOneElectiveData(res_single);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {oneElectiveData.map((project) => (
        <div>
          <HeroSpace
            image={project.projectBanner.url}
            h1={project.projectTitle}
          />
          <Container fixed>
            <Grid container>
              <Grid item xs={12} sm={7}>
                <h3 className={"text-yellow"}>{project.headline}</h3>
                <p className={"text-white"}>{project.paragraph}</p>
              </Grid>
            </Grid>
            <Grid container direction={"row-reverse"}>
              <Grid item xs={12} sm={7}>
                {project.mainVideo ? (
                  <VideoGallery videos={[{url : project.url, title: ""}]}></VideoGallery>
                  ) : (
                    <img
                      src={project.projectThumbnail.url}
                      alt={project.projectThumbnail.alternativeText}
                      className={"electiveMainImage"}
                    />
                )}
              </Grid>
            </Grid>
            {project.images ? 
              <ImageGallery images={project.images}/>
            : null}
          </Container>
        </div>
      ))}
    </div>
  );
};

export default Elective;
