import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import HeroSpace from "components/global/HeroSpace/HeroSpace";
import Topic from "components/MultiTopic/Topic/Topic";
import VideoGallery from "components/VideoGallery/VideoGallery";
import ImageGallery from "components/ImageGallery/ImageGallery";
import StudentCards from "components/StudentCards/StudentCards";
import InfoCard from "components/SemesterTopicInfoCard/InfoCard/InfoCard";
import * as ProjectService from "services/ProjectService.tsx";
import Grid from "@material-ui/core/Grid";

import { useLocation } from "react-router-dom";
import "./Project.scss";
import "./projectRow.scss";
import { Container } from "@material-ui/core";

export const Project = (props) => {
  const [oneProjectData, setOneProjectData] = React.useState([]);

  const { pathname } = useLocation();

  const URLseparated = pathname.split("/");
  const URLprojectID = URLseparated[URLseparated.length - 1];

  //   let topicCollection = [];
  //   let newTopic = {
  //     heading: "",
  //     paragraph: "",
  //     image: "",
  //   };
  //   let i = 0;

  const getData = async () => {
    const res_single = await ProjectService.findProjectById(URLprojectID);
    setOneProjectData(res_single);
  };

  //     // console.log(res_single);
  //     if (res_single[0].articles.length > res_single[0].images.length) {
  //     } else if (res_single[0].articles.length < res_single[0].images.length) {
  //     } else {
  //       while (i < res_single[0].articles.length) {
  //         newTopic.heading = res_single[0].articles[i].headline;
  //         newTopic.paragraph = res_single[0].articles[i].paragraph;
  //         newTopic.image = res_single[0].images[i].url;
  //         topicCollection.push(newTopic);
  //         i++;
  //       }
  //     }
  //     console.log(topicCollection);
  //   };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  return (
    <div>
      {oneProjectData.map((project) => (
        <div>
          <HeroSpace
            image={project.projectBanner.url}
            subtitle={project.description}
            h1={project.projectTitle}
            ProjectTitleColor={project.ProjectTitleColor}
            HeaderTextColor={project.HeaderTextColor}
            HeaderBackgroundColor={project.HeaderBackgroundColor}
          />
          <Container fixed>
            <Topic
              {...(project.imageToText
                ? {
                    image: project.imageToText.url,
                    alt: project.imageToText.alternativeText,
                  }
                : null)}
              heading={project.textToImage_headline}
              paragraph={project.textToImage_paragraph}
            />
            {/* <MultiTopic topics={topicCollection} /> */}
            {/* <InfoCardCollection articles={project.articles} /> */}
            <GenerateSite
              articles={project.articles}
              images={project.images}
            ></GenerateSite>
            {/* <ImageGallery images={project.images} /> */}
            <VideoGallery videos={project.videos} />
            <StudentCards people={project.teamMembers} />
          </Container>
          <Container>
            <NavLink
              to="/projects"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              style={{ textDecoration: "none" }}
            >
              <button className="primary home-centered-button">
                Zurück zur Projektübersicht
              </button>
            </NavLink>
          </Container>
        </div>
      ))}
    </div>
  );
};
export default Project;

const ProjectGridItems = (props) => {
  const image = props.image
    ? props.image.url
    : null;
  let articleOnly = (
    <Grid item xs={12} md={6}>
      <InfoCard
        headline={props.article.headline}
        paragraph={props.article.paragraph}
      />
    </Grid>
  );
  let imageOnly = (
    <Grid item xs={12}>
      <img src={image} className={"project_GridImage"} alt="" />
    </Grid>
  );
  let both = (
    <>
      <Grid item xs={12} md={6}>
        <InfoCard
          headline={props.article.headline}
          paragraph={props.article.paragraph}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <img src={image} className={"project_GridImage"} alt="" />
      </Grid>
    </>
  );

  if (props.article && props.image) return both;
  if (props.article) return articleOnly;
  if (props.image) return imageOnly;
  return null;
};

const GenerateGrid = (props) => {
  let dir = props.imageRight ? "row" : "row-reverse";
  return (
    <>
      <Grid container spacing={6} direction={dir} alignItems={"center"}>
        <ProjectGridItems article={props.article} image={props.image} />
      </Grid>
    </>
  );
};

const GenerateSite = (props) => {
  let grids = props.articles.map((article, index) => (
    <GenerateGrid
      article={article}
      image={props.images[index] ? props.images[index] : null}
      imageRight={index % 2 === 0 ? true : false}
    ></GenerateGrid>
  ));
  let imageGrid;
  if (props.images.length > props.articles.length) {
    imageGrid = (
      <ImageGallery
        images={props.images.splice(
          props.articles.length - 1,
          props.images.length - props.articles.length
        )}
      />
    );
    grids.concat();
  }
  return (
    <>
      {grids}
      {imageGrid}
    </>
  );
};
