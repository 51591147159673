import React from 'react'
import './VideoGallery.scss'
import VideoCard from './VideoCard/VideoCard'
import { Container } from '@material-ui/core'

const VideoGallery = props => {
  if (!Array.isArray(props.videos))
  {
    return null;
  }
  if (props.videos.length === 1)
  {
    return (
      <Container>
        <div className="videoGallery_container_single-vid">
          <VideoCard title={`${props.videos[0].title}`} src={props.videos[0].url} frameborder="0"/>
        </div>
      </Container>
    );
  }
  else if (props.videos.length === 2 || props.videos.length === 4)
  {
    return (
      <Container>
        <div className="videoGallery_container_double-vid">
          {props.videos.map((video) => (
              <VideoCard title={`${video.title}`} src={video.url} frameborder="0"/>
          ))}
        </div>
      </Container>
    );
  }
  else if (props.videos.length > 2)
  {
    return (
      <Container>
        <div className="videoGallery_container">
          {props.videos.map((video) => (
              <VideoCard title={`${video.title}`} src={video.url} frameborder="0"/>
          ))}
        </div>
      </Container>
    )
  }
  return null;
}

export default VideoGallery;
