import React, { useEffect } from "react";
import { Container } from '@material-ui/core';
import PageHead from 'components/global/PageHead/PageHead';
import MultiTopic from "components/MultiTopic/MultiTopic";

export const LegalNotice = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <PageHead pageName="Impressum"/>
      <MultiTopic
        topics = {[
          {
            heading: "Angaben gemäß §5 TMG",
            paragraph:
              <p>
                <div>IMD-F – Interactive Media Design Förderverein e.V.</div>
                <div>Max-Planck-Strasse 2</div>
                <div>64807 Dieburg</div>
                <div>Vereinsregister: VR 84108</div>
                <div> Registergericht: Amtsgericht Darmstadt </div>
                <div>Vertreten durch: Vorsitzende Andrea Krajewski</div>
              </p>
          },
          {
            heading: "Kontakt",
            paragraph:
              <p>
                <div>Telefon: +49-6151-16-39450</div>
                <div>E-Mail: <a className="text-yellow" href="mailto:info@imd-f.de">info@imd-f.de</a></div>
              </p>      
          }, 
          {
            heading: "Postadresse",
            paragraph:
              <p>
                <div>IMD-F – Interactive Media Design Förderverein e.V.</div>
                <div>c/o</div>
                <div>Hochschule Darmstadt</div>
                <div>University of Applied Sciences</div>
                <div>Mediencampus</div>
                <div>Max-Planck-Strasse&nbsp;2</div>
                <div>64807 Dieburg</div>
              </p>      
          },
          {
            heading: "Verbraucherstreitbeilegung / Universalschlichtungsstelle",
            paragraph:
              <div>
                <div>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</div>
                <div>Quelle: <a className="text-yellow" target="_blank" rel="noopener noreferrer" href="https://www.e-recht24.de/">e-recht24.de</a></div>
              </div>     
          },
        ]}
       
      /> 
    </Container>
  );
};


export default LegalNotice;
