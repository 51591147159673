import React from 'react'
import "./StudentCards.scss"
import Grid from '@material-ui/core/Grid'
import arrows from '../../assets/svg/arrows.svg'
import StudentCard from './StudentCard/StudentCard'

const StudentCards = (props) => {
  return (
    <div>
      <div className='studentCards_container'>
        {Array.isArray(props.people) ? props.people.map(person => (
          <StudentCard
            name={person.name}
            subline={person.about}
            mail={person.email}
            img={person.img}
          ></StudentCard>
        )) : null}
      </div>
      <Grid container>
        <Grid item xs={6}></Grid>
        <Grid item xs={5}>
          <img
            className='studentCards_arrows'
            src={arrows}
            alt='scroll horicontal'
          ></img>
        </Grid>
      </Grid>
    </div>
  )
}

export default StudentCards;
