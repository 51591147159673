import React from "react";
import "./HeroSpace.scss";
import Container from "@material-ui/core/Container";

const HeroSpace = (props) => {
  let right = "";
  props.textRight ? (right = "if-hero-space_right") : (right = "");

  let visibleH2 = "";
  props.h2 === "noText"
    ? (visibleH2 = " if-hero-space_transparent")
    : (visibleH2 = "");
  return (
    <div className="if-hero-space_root">
      <div
        className="if-hero-space_image if-hero-space_desktopOnly"
        style={{ backgroundImage: `url(${props.image})` }}
      ></div>

      <div
        className="if-hero-space_image if-hero-space_mobileOnly"
        {...(props.xsImage
          ? {
              style: {
                backgroundImage: `url(${props.xsImage})`,
              },
            }
          : {
              style: {
                backgroundImage: `url(${props.image})`,
              },
            })}
      ></div>
      <Container className="if-hero-space_container">
        <div className={`if-hero-space_text ${right}`}>
          <h2 className={"if-hero-space_text_h2" + visibleH2}>{props.h2}</h2>
          <div
            className={
              `if-hero-space_text_h1 text-${props.ProjectTitleColor}`
            }
          >
            {props.h1}&nbsp;──
          </div>
          <p
            className={`if-hero-space_text_subtitle text-${props.HeaderTextColor} if-hero-space_background_${props.HeaderBackgroundColor}`}
          >
            {props.subtitle}
          </p>
        </div>
        <img
          className="if-hero-space_text_arrow-down"
          src="/ArrowDown.min.svg"
          alt=""
        />
      </Container>
    </div>
  );
};

export default HeroSpace;
