import React from 'react'
import '../PeopleCards.scss'
import { Link } from 'react-router-dom'

const PeopleCard = props => {
  return (
    <Link to={props.link} className='peopleCard_container box-shadow hover'>
      <img src={props.img} alt={'Speaker: ' + props.name} title={props.name}/>
      <div className='peopleCard_container_text'>
        <p
          className='peopleCard_container_text_name'>
          {props.name}
        </p>
        <p
          className='peopleCard_container_text_subline'>
          {props.subline}
        </p>
        {props.mail && (
          <a className='peopleCard_container_text_mail' href={"mailto:"+props.mail}>E-Mail schreiben</a>
        )}
      </div>
    </Link>
  )
}

export default PeopleCard;
