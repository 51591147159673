import React from 'react'
import './Navbar.scss'
import { NavLink, withRouter } from 'react-router-dom'

const NavigationButton = props => {
  return (
    <button
      className={props.className + ' burger-menu'}
      onClick={props.toggleMenu}
    >
      {/*<img src={burgerMenu} ></img>*/}
    </button>
  )
}

const NavItems = props => {
  //const allItems = document.getElementsByClassName('if-navbar_container_item')
  //const history = useHistory()
  return (
    <div className={props.name}>
      {/* <ul> */}
      {/*<Link to="/ifExhibition" className="if-navbar_container_item">
        Startseite
      </Link>*/}
      <NavLink
        to='/home'
        className='if-navbar_container_item'
        onClick={props.toggleMenu}
      >
        <li>Startseite</li>
      </NavLink>
      <NavLink
        to='/if-exhibition'
        className='if-navbar_container_item'
        onClick={props.toggleMenu}
      >
        <li>Über die If</li>
      </NavLink>
      <NavLink
        to='/projects'
        className='if-navbar_container_item'
        onClick={props.toggleMenu}
      >
        <li>Projekte</li>
      </NavLink>
      <NavLink
        to='/electives'
        className='if-navbar_container_item'
        onClick={props.toggleMenu}
      >
        <li>Electives</li>
      </NavLink>
      <NavLink
        to='/program'
        className='if-navbar_container_item'
        onClick={props.toggleMenu}
      >
        <li>Programm</li>
      </NavLink>
      <NavLink
        to='/speakers'
        className='if-navbar_container_item'
        onClick={props.toggleMenu}
      >
        <li>Speakers</li>
      </NavLink>
      {/* </ul> */}
    </div>
  )
}

class Navbar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      expanded: false
    }
  }

  toggleMenu () {
    this.setState({ expanded: !this.state.expanded }, () => {
      if (this.state.expanded) {
        document.body.classList.add('noscroll')
      } else {
        document.body.classList.remove('noscroll')
      }
    })
  }

  render () {
    const navRootExpanded = this.state.expanded ? 'expanded' : ''
    const navItemsHidden_Desktop = this.state.expanded ? ' hide' : ''
    const navItemsHidden_Mobile = !this.state.expanded ? ' hide' : ''
    return (
      <div className={'navigation ' + navRootExpanded}>
        <div className='if-navbar_container'>
          <NavLink
            to='/home'
            className='if-navbar_container_item'
            activeClassName='logoActive'
          >
            <div className='if-navbar_container_logo'></div>
          </NavLink>
          <NavItems
            name={'if-navbar_container_links ' + navItemsHidden_Desktop}
            toggleMenu={() => {}}
          />
          <NavLink to='/stage' onClick={() => this.state.expanded && this.toggleMenu()} activeClassName="activeStage">
            {/* <button className='if-navbar_container_button'>Live-Bühne</button> */}
          </NavLink>
          <NavigationButton
            className={'if-navbar_container_item mobile'}
            toggleMenu={() => this.toggleMenu()}
          />
        </div>
        <NavItems
          name={'if-navbar_link_list' + navItemsHidden_Mobile}
          toggleMenu={() => this.toggleMenu()}
        />
      </div>
    )
  }
}

export default withRouter(Navbar);
