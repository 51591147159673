import React from "react";
import Topic from "components/MultiTopic/Topic/Topic";

const MultiTopic = (props) => {
  const topics = !props.topics
    ? null
    : props.topics.map((topic, index) => {
      if (Object.keys(topic).length === 0 && topic.constructor === Object)
      {
        return null;
      }
      else
      {
        return(
          <Topic
            heading={topic.heading}
            paragraph={topic.paragraph}
            image={topic.image}
            button={topic.button}
            imageLeft={index % 2 === 0 ? true : false}
            backgroundColor={props.alternateBackgroundColor && index % 2 === 1 ? "#3720AE" : "transparent"}
          />
        )
      }
    });
  return topics;
};

export default MultiTopic;
