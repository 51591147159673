import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./App.scss";
import NavBar from "components/global/Navbar/Navbar";
import Footer from "components/global/Footer/Footer";

import Home from "pages/Home/Home";
import IfExhibition from "pages/IfExhibition/IfExhibition";
import Program from "pages/Program/Program";
import Projects from "pages/Projects/Projects";
import Project from "pages/Projects/Project/Project";
import Speakers from "pages/Speakers/Speakers";
import Electives from "pages/Electives/Electives";
import Elective from "pages/Electives/Elective/Elective";
import Stage from "pages/Stage/Stage";
import LegalNotice from "pages/LegalNotice/LegalNotice";
import Privacy from "pages/Privacy/Privacy";
import Contact from "pages/Contact/Contact";

export default function App() {
	return (
		<Router onUpdate={() => window.scrollTo(0, 0)} basename={process.env.PUBLIC_URL}>
			<NavBar/>
			<Route exact path="/" component={Home}/>
			<Route path="/home" component={Home}/>
			<Route path="/if-exhibition" component={IfExhibition}/>
			<Route path="/project" component={Project}/>
			<Route path="/projects" component={Projects}/>
			<Route path="/program" component={Program}/>
			<Route path="/speakers" component={Speakers}/>
			<Route path="/electives" component={Electives}/>
			<Route path="/elective" component={Elective}/>
			<Route path="/legal-notice" component={LegalNotice}/>
			<Route path="/privacy" component={Privacy}/>
			<Route path="/contact" component={Contact}/>
			<Route path="/stage" component={Stage}/>
			<Footer />
		</Router>
	);
}
